import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { IconWrapper } from '../../ui';
// forms
import { DatePicker, CalendarFormGroup } from '../restyled';
//////////////////////////////////////////////////

const setTimeFormat = (timeSelection: boolean) => (
  G.ifElse(
    R.equals(timeSelection, true),
    GC.DEFAULT_DATE_TIME_FORMAT,
    GC.DEFAULT_DATE_FORMAT,
  )
);

class Calendar extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;

    setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const { id, onChange, setFieldValue, shouldCustomChange } = this.props;

    if (R.isNil(value)) {
      return setFieldValue(id, null);
    }

    const timeFormat = setTimeFormat(this.props.timeSelection);

    if (shouldCustomChange) {
      return onChange({ target: { id, value: G.createLocalDateTimeFromInstanceOrISOString(value, timeFormat) } });
    }

    setFieldValue(id, G.createLocalDateTimeFromInstanceOrISOString(value, timeFormat));
  };

  render() {
    const {
      id,
      value,
      width,
      error,
      touched,
      minDate,
      maxDate,
      hideIcon,
      placeholder,
      isClearable,
      excludeDates,
      borderRadius,
      timeSelection,
      pathToIconColor,
      additionalBorderColor,
      useAdditionalBorderColor,
    } = this.props;

    const selected = G.isNotNilAndNotEmpty(value) ? new Date(value) : null;

    const setMaxDate = () => {
      if (G.isNotNilAndNotEmpty(maxDate)) {
        return maxDate;
      }

      return null;
    };

    const setMinDate = () => {
      if (G.isNotNilAndNotEmpty(minDate)) {
        return new Date(G.convertInstanceToDefaultDateFormat(minDate));
      }

      return null;
    };

    let iconColor = G.getTheme('icons.iconColor');

    if (G.isNotNilAndNotEmpty(pathToIconColor)) {
      iconColor = G.getTheme(pathToIconColor);
    }

    const timeIntervals = R.or(G.getCalendarTimeIntervalConfigFromWindow(), 15);

    return (
      <CalendarFormGroup direction='row'>
        <DatePicker
          id={id}
          value={value}
          error={error}
          touched={touched}
          autoComplete='off'
          todayButton='Today'
          selected={selected}
          portalId='root-portal'
          maxDate={setMaxDate()}
          minDate={setMinDate()}
          showPopperArrow={false}
          showYearDropdown={true}
          showMonthDropdown={true}
          onBlur={this.handleBlur}
          borderRadius={borderRadius}
          excludeDates={excludeDates}
          onChange={this.handleChange}
          timeIntervals={timeIntervals}
          isClearable={R.or(isClearable, false)}
          placeholderText={R.or(placeholder, '')}
          showTimeSelect={R.or(timeSelection, false)}
          additionalBorderColor={additionalBorderColor}
          onKeyDown={(e: Object) => e.preventDefault()}
          width={`${R.subtract(parseInt(width, 10), 36)}px`}
          useAdditionalBorderColor={useAdditionalBorderColor}
          dateFormat={G.ifElse(timeSelection, GC.DEFAULT_DATE_TIME_FORMAT, GC.DEFAULT_DATE_FORMAT)}
        />
        {
          G.ifElse(
            hideIcon,
            null,
            <label htmlFor={id}>
              <IconWrapper
                cursor='pointer'
                mr={R.pathOr('10px', ['calendarIconMr'], this.props)}
                ml={R.pathOr('10px', ['calendarIconMl'], this.props)}
              >
                {I.calendar(iconColor, 16, 15)}
              </IconWrapper>
            </label>,
          )
        }
      </CalendarFormGroup>
    );
  }
}

export default Calendar;
