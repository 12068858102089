import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

export const defaultAppointmentSectionFields = {
  [GC.FIELD_STOP_NUMBER]: '',
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: '',
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: '',
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: '',
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: '',
};

export const appointmentSectionFields = [
  {
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    minDate: G.getCurrentDate(),
    placeholder: 'Select a weekday',
    maxDate: G.dateAddYearsFromCurrent(3),
    fieldName: GC.FIELD_LOAD_APPOINTMENT_DATE,
    label: ['titles:appointment-date', 'Appointment Date'],
  },
  {
    type: 'timeInput',
    inputWrapperStyles,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
    label: ['titles:early-time', 'Early Time'],
  },
  {
    type: 'timeInput',
    inputWrapperStyles,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    label: ['titles:late-time', 'Late Time'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_NUMBER,
    label: ['titles:appointment-number', 'Appointment Number'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_STOP_NUMBER,
    label: ['titles:pickup-drop-number', 'Pickup/Drop Number'],
  },
];

export const appointmentSectionValidationSchema = () => Yup.lazy(() => Yup.object().shape({
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: Yup.string()
    .nullable(true)
    .min(2, G.getShouldBeFromToCharLocaleTxt(2, 100))
    .max(100, G.getShouldBeFromToCharLocaleTxt(2, 100)),
  [GC.FIELD_STOP_NUMBER]: Yup.string()
    .nullable(true)
    .min(1, G.getShouldBeFromToCharLocaleTxt(1, 40))
    .max(40, G.getShouldBeFromToCharLocaleTxt(1, 40)),
}));
