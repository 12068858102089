import * as R from 'ramda';
import MaskedInput from 'react-text-mask';
import React, { useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
// features
import { Input, MuiIconWrapper } from '../../../features/new-do/ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// forms
import { FormGroup } from '../../ui';
//////////////////////////////////////////////////

const militaryFormats = [GC.DATE_TIME_FORMAT_US_24H];

export const getDateTimeFormatForRender = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );

  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return {
    timeFormat: R.path(['time', 'format'], formatDataObject),
    dateTimeFormat: R.path([propName, 'format'], formatDataObject),
  };
};

export const getDateTimeFormatForValue = (timeSelection: boolean) => (
  G.ifElse(
    R.equals(timeSelection, true),
    GC.DEFAULT_DATE_TIME_FORMAT,
    GC.DEFAULT_DATE_FORMAT,
  )
);

export const getMask = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );

  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return R.path([propName, 'mask'], formatDataObject);
};

export const getPipe = (timeSelection: boolean, format: string) => {
  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );

  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  return R.path([propName, 'pipe'], formatDataObject);
};

export const getCorrectDateTimeString = (value: any, format: string) => {
  if (R.and(G.isNotNilAndNotEmpty(value), G.isValidDate(value))) {
    const isMilitary = R.includes(format, militaryFormats);

    const valueToUse = G.createLocalDateTimeFromInstanceOrISOString(
      value,
      R.path([format, 'dateTime', 'format'], GC.dateTimeFormatMap),
    );

    const splitted = R.split(' ', valueToUse);
    const date = R.head(splitted);

    let time = R.or(R.nth(1, splitted), '00:00');

    const ampm = R.or(R.nth(2, splitted), 'am');
    const splittedTime = R.split(':', time);
    const hour = R.head(splittedTime);
    const hourHead = R.head(hour);
    const hourLength = R.length(hour);
    const min = R.nth(1, splittedTime);
    const hourNumber = G.toNumber(hour);

    if (R.and(R.lt(hourNumber, 10), R.and(R.equals(hourLength, 1), G.isNotZero(hourHead)))) {
      time = `0${hour}:${min}`;
    }

    if (isMilitary) return `${date} ${time}`;

    return R.toUpper(`${date} ${time} ${ampm}`);
  }

  return value;
};

export const getSelected = (value: Object) => {
  if (R.and(G.isNotNilAndNotEmpty(value), G.isValidDate(value))) {
    return G.getNewDate(value);
  }

  return null;
};

export const MaskedTimeInput = (props: Object) => {
  const { value, format, inputStyles, handleChangeInput } = props;

  const pipe = (value: any) => {
    if (G.isNotNilAndNotEmpty(value)) {
      let newValue = value;

      const splittedHead = R.head(R.split(':', value));
      const splittedHeadNumber = G.toNumber(splittedHead);
      const isMilitary = R.includes(format, militaryFormats);
      const numberToCompare = G.ifElse(isMilitary, 23, 12);

      if (R.gt(splittedHeadNumber, numberToCompare)) {
        const valueToReplace = G.ifElse(isMilitary, '00', 12);

        newValue = R.replace(splittedHead, valueToReplace, value);
      }

      if (G.isFunction(handleChangeInput)) {
        handleChangeInput({ ...props, value: R.toUpper(value) });
      }

      return R.toUpper(newValue);
    }

    return value;
  };

  const initialValue = G.setCorrectTimeInitialValue(value);

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );

  const { mask, placeholder } = formatDataObject.time;

  return (
    <MaskedInput
      {...props}
      pipe={pipe}
      mask={mask}
      value={initialValue}
      render={(ref: Object, props: Object) => (
        <Input {...props} {...G.spreadUiStyles(inputStyles)} ref={ref} placeholder={placeholder} />
      )}
    />
  );
};

const MaskedDateInput = (props: Object) => {
  const { format, timeSelection, inputStyles } = props;

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );

  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');

  const placeholder = R.path([propName, 'placeholder'], formatDataObject);

  return (
    <MaskedInput
      {...props}
      pipe={getPipe(timeSelection, format)}
      mask={getMask(timeSelection, format)}
      render={(ref: Object, props: Object) => (
        <Input {...props} {...G.spreadUiStyles(inputStyles)} ref={ref} placeholder={placeholder} />
      )}
    />
  );
};


const CustomInput = ({ disabled, InputProps, handleClick }: Object) => (
  disabled ? null : (
    <MuiIconWrapper
      ml={-20}
      height={36}
      cursor='pointer'
      onClick={handleClick}
    >
      {InputProps.endAdornment}
    </MuiIconWrapper>
  )
);

export const DateTimeInput = (props: Object) => {
  const {
    id,
    value,
    format,
    minDate,
    maxDate,
    disabled,
    setFieldValue,
    timeSelection,
    timeIntervals,
    isClearable = true,
    shouldCustomChange,
    customChangeHandler2,
    disablePortal = false,
  } = props;

  const ref = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: Event) => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (value: string) => {
    if (R.isNil(value)) {
      setFieldValue(id, null);

      return;
    }

    if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler2))) {
      return customChangeHandler2({ value, props });
    }

    const dateTimeFormat = getDateTimeFormatForValue(timeSelection);
    const valueToSet = G.createLocalDateTimeFromInstanceOrISOString(value, dateTimeFormat);

    setFieldValue(id, valueToSet);
  };

  const selected = getSelected(value);
  const configDateFormat = R.or(format, GC.DATE_TIME_FORMAT_US);

  const getCorrectValue = () => {
    if (G.isTrue(timeSelection)) return getCorrectDateTimeString(value, configDateFormat);

    if (R.not(G.isValidDate(value))) return value;

    return G.createLocalDateTimeFromInstanceOrISOString(
      value,
      R.path([configDateFormat, 'date', 'format'], GC.dateTimeFormatMap),
    );
  };

  const newValue = getCorrectValue();

  const setMaxDate = () => {
    if (G.isFunction(maxDate)) return maxDate(props);

    if (G.isNotNilAndNotEmpty(maxDate)) return maxDate;

    return G.addDateTime(new Date(), 100, 'years');
  };

  const setMinDate = () => {
    if (G.isFunction(minDate)) return minDate(props);

    if (G.isNotNilAndNotEmpty(minDate)) return minDate;

    return G.subtractDateTime(new Date(), 100, 'years');
  };

  const { timeFormat, dateTimeFormat } = getDateTimeFormatForRender(timeSelection, configDateFormat);

  const dateTimePickerProps = {
    open: isOpen,
    value: selected,
    autoFocus: false,
    onClose: handleClose,
    maxDate: setMaxDate(),
    minDate: setMinDate(),
    onChange: handleChange,
    format: dateTimeFormat,
    minutesStep: timeIntervals,
    slots: { textField: CustomInput },
    ampm: R.equals(timeFormat, GC.DEFAULT_TIME_FORMAT),
    slotProps: {
      textField: { disabled, handleClick },
      actionBar: G.ifElse(isClearable, { actions: ['clear'] }),
      popper: {
        disablePortal,
        anchorEl: ref.current,
        sx: { '.MuiPickersArrowSwitcher-root': { zIndex: 12 }},
      },
    },
  };

  const renderDatePicker = () => {
    if (G.isTrue(timeSelection)) {
      return (
        <DesktopDateTimePicker
          {...dateTimePickerProps}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      );
    }

    return <DesktopDatePicker {...dateTimePickerProps} />;
  };


  return (
    <Flex ref={ref}>
      <MaskedDateInput {...props} value={newValue} format={configDateFormat} />
      <FormGroup direction='row'>
        <ClickAwayListener onClickAway={handleClose}>
          <div>{renderDatePicker()}</div>
        </ClickAwayListener>
      </FormGroup>
    </Flex>
  );
};
